header > nav {
  font-family: "effra";
  text-transform: uppercase;
}

body > main > section h1:nth-of-type(1) {
  font-family: "aesthet-nova";
}

.logo {
}

.logo > img {
  object-fit: scale-down;
  width: auto;
  height: 6rem;
}

.blog-roll {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1em;
}

.blog-roll-item {
  display: grid;
  grid-template-columns: 1fr, 2fr;
  grid-template-areas: "img content";
}

